import { mapActions, mapGetters } from 'vuex';

//---helpers
import { BLOG_CONTENT_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import IconClock from '@/components/icons/IconClock.vue';
import ContactPage from '@/modules/pages/contacts';
import ContentBlock from '@/components/custom/ContentBlock/index.vue';

export default {
  name: 'Archives',
  mixins: [dynamicHead],
  components: { ContentBlock, ContactPage, IconClock, BreadcrumbsSimple },
  props: {},
  data() {
    return {
      BLOG_CONTENT_TYPE,
      breadcrumbs: [],
      breadcrumbsTitle: null,
      archiveData: null
    };
  },
  created() {
    this._loadContent();
  },
  mounted() {},
  watch: {
    archives: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this._updateBreadcrumbs();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      archives: 'blog/archives',
      metaData: 'meta/metaData'
    })
  },
  methods: {
    ...mapActions({
      getBlogArchive: 'blog/GET_BLOG_ARCHIVE',
      getMetaData: 'meta/GET_META_DATA'
    }),
    _updateBreadcrumbs() {
      let { year, month } = this.$route.params;
      if (month) {
        let findObj = this.archives.find((item) => item.year === Number(year) && item.month === Number(month));
        this.breadcrumbsTitle = findObj ? findObj.title : year;
        this.breadcrumbs = [{ title: year, route: { name: 'archive', params: { year } } }];
      } else {
        this.breadcrumbsTitle = year;
      }
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      let { year, month } = this.$route.params;
      let params = month ? { year, month } : { year };

      promiseCollection.push(
        this.getBlogArchive(params).then((result) => {
          this.archiveData = result;
        })
      );
      promiseCollection.push(
        this.getMetaData('info').then(() => {
          this._setMetaParams();
        })
      );
      this._setMetaParams();
      this.setMeta();
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaDescription, image } = this.metaData;

      let { year, month } = this.$route.params;
      let metaTitle = year;
      if (month) {
        let findObj = this.archives.find((item) => item.year === Number(year) && item.month === Number(month));
        metaTitle = findObj ? `${findObj.title} ${year}` : year;
      }

      this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;

      this.setMeta();
    }
  }
};
